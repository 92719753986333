import { useRouter } from 'next/router';

/**
 * getGCLID
 * This function simply grabs the [GCLID](https://support.google.com/google-ads/answer/9744275) param from the URL, if it exists
 * @category Segment
 * @func
 * @return {string}
 */
export function getGCLID(): string {
  const url = new URL(location.href);

  return url.searchParams.get('gclid') || '';
}

/**
 * useGCLID
 * This hook simply grabs the [GCLID](https://support.google.com/google-ads/answer/9744275) param from the URL, if it exists
 * @category Segment
 * @func
 * @return {string|undefined}
 */
export default function useGCLID(): string | undefined {
  const router = useRouter();
  const { gclid } = router.query;

  return Array.isArray(gclid) ? gclid[0] : gclid;
}
