import { useEffect, useState } from 'react';

const useReduceMotion = (defaultValue = true): boolean => {
  const [isReducedMotion, setReducedMotion] = useState<boolean>(defaultValue);

  useEffect(() => {
    const mediaQuery = window.matchMedia('(prefers-reduced-motion: reduce)');

    if (mediaQuery) {
      setReducedMotion(mediaQuery.matches);

      mediaQuery.addEventListener('change', () => {
        setReducedMotion(mediaQuery.matches);
      });
    }

    return () => {
      mediaQuery.removeEventListener('change', () => {
        setReducedMotion(mediaQuery.matches);
      });
    };
  }, []);

  return isReducedMotion;
};

export default useReduceMotion;
