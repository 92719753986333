// Variable for determine if in prod environment
export const isProd = process.env.ENVIRONMENT === 'production';

// Base URL for the site. This is used for static pathing assets
export const baseUrl = process.env.baseUrl;

// Base Proxy URL for the site. This is used for static pathing proxied assets
export const baseProxyUrl = isProd
  ? 'https://plus.walmart.aws.reddigital.tech'
  : 'https://plus.walmart-stg.aws.reddigital.tech';

// Two values used for W+'s Beacon tracking script
export const beaconBdValue = isProd
  ? 'beacon.walmart.com'
  : 'beacon.qa.walmart.com';

export const beaconBhValue = isProd
  ? 'beacon.lightest.walmart.com'
  : 'beacon.qa.lightest.walmart.com';

// New Relic Constants for the tracking snippet
export const accountID = isProd ? '3767831' : '3767832';
export const trustKey = isProd ? '78034' : '78034';
export const agentID = isProd ? '772289984' : '772289986';
export const licenseKey = isProd ? 'NRBR-5749a64f9542a1b0b04' : 'NRBR-d8218942a368b955b7e';
export const applicationID = isProd ? '772289984' : '772289986';

// Monarch constants
export const monarch = {
  // all envs will point to prod
  sourceId: '13823972-6eef-438b-86ef-c6d2023ffc71',
  clientToken: 'ae0a7e90-9367-4a9b-aedd-04e7592d6e42',
};

export const MONARCH_RULE_SET = 'globalRuleSet';
