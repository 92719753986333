import React from 'react';

interface IsReducedMotionContext {
  isReducedMotion: boolean;
}

const ReducedMotionContext = React.createContext<IsReducedMotionContext>({
  isReducedMotion: true,
});

export default ReducedMotionContext;
