/* eslint-disable local-rules/no-localstorage */
import { logError } from '@red-digital/bricks';

/**
 * @class
 * @category Helper
 * When a user turns off all cookies, trying to use Local Storage results in
 * a security error. This alternative class handles wrapping the get/set items
 * in try catch statements
 * */
export default class SafeLocalStorage {
  static defaultErrorMsg: 'Cannot access local storage';

  /**
 * getItem
 * Acts just like the localStorage getItem function except it parses the JSON for you
 * and is wrapped with a try/catch
 * for the rare case where localStorage is not accessible
 * @func
 * @returns {NonNullable<T> | undefined}
 * @category Helper
 */
  static getItem<T>(keyName: string): NonNullable<T> | undefined {
    try {
      const val = localStorage.getItem(keyName);
      return val ? JSON.parse(val) : undefined;
    } catch (e) {
      logError((e || this.defaultErrorMsg) as Error);
      return undefined;
    }
  }

  /**
 * setItem
 * Acts just like the localStorage setItem function except wrapped with a try/catch
 * for the rare case where localStorage is not accessible
 * @func
 * @param {string} keyName - the key the stored item will live under in localStorage
 * @param {string} keyValue - the value that is being stored
 * @category Helper
 */
  static setItem(keyName: string, keyValue: any) {
    try {
      localStorage.setItem(keyName, JSON.stringify(keyValue));
    } catch (e) {
      logError((e || this.defaultErrorMsg) as Error);
    }
  }
}
