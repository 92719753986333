import {
  getAnonymousID,
  joinURLParams,
  parseURLParams,
} from '~/helper/parseURLParams';
import SafeLocalStorage from './SafeLocalStorage';

/**
 * The goal of this function is to pass any incoming URL parameters on to Walmart+ to ensure that responses and conversions are properly attributed to RV.
 * - Grab **anonymous_id** from `document.cookie` and pass to Walmart+ as **rv_click_id**
 * - If URL params exist, store in LocalStorage, overriding any that already exist.
 * - If no URL params, look in LocalStorage. If params exist there,
 * attach them to each applicable href
 *
 * @func
 * @returns {Object} Returns an object of mapped URL parameters, or an empty object if `window` does not exist (i.e., if we are in server environment)
 *
 * @category Helper
 * @subcategory URL Tracking
 */
export default function trackURLParams(): { [key: string]: string } {
  if (typeof window === 'undefined') return {};

  /* eslint-disable @typescript-eslint/no-unused-vars */
  /* eslint-disable-next-line @typescript-eslint/naming-convention */
  const [_, urlParams] = location.href.split('?');

  let params: { [key: string]: string } = {};

  if (!!urlParams && urlParams.length > 0) {
    params = {
      ...params,
      ...parseURLParams(location.href),
    };

    // If a user blocks cookies, access to localStorage will be blocked and throw errors
    SafeLocalStorage.setItem('urlParams', JSON.stringify(params));
  } else {
    // If a user blocks cookies, access to localStorage will be blocked and throw errors
    const storedParams: Record<string, string> = SafeLocalStorage.getItem('urlParams') ?? {};
    params = storedParams;
  }

  return params;
}

/**
 * Combines key:value sets of URL params with a link's `href` and returns a final string. The parameter **rv_click_id** is intentionally placed first to account for a scenario in which the string is cut off due to excessive characters. **rv_click_id** is our bread and butter, so it's important to ensure that this parameter is always passed along to Walmart.
 * @func
 * @param {string} url - The link's full url/href
 * @returns {string}
 *
 * @category Helper
 * @subcategory URL Tracking
 */
export const formatTrackedURL = (url: string): string => {
  const [base] = url.split('?');

  /* eslint-disable-next-line @typescript-eslint/naming-convention */
  const rv_click_id = getAnonymousID() || '';
  const storedParams = trackURLParams();

  // If there is no anonymous_id, and no URL params or params stored in
  // `LocalStorage`, return the link's original URL to avoid having a trailing
  // `?` in the href
  if (!rv_click_id && Object.keys(storedParams).length === 0) {
    return url;
  }

  const parsedLinkParams = parseURLParams(url);

  return `${base}?${joinURLParams({
    rv_click_id,
    ...storedParams,
    ...parsedLinkParams,
  })}`;
};

/**
 * Getting down with some [recursion](https://www.freecodecamp.org/news/recursion-is-not-hard-858a48830d83/)! This function iterates over a list of DOM elements.
 * 1. If a given item is an `a` element and does not have the `data-disable-param-persist` attribute, then we update its `href` property to include the **rv_click_id** and any other incoming URL params, via {@link formatTrackedURL}.
 * 2. If the element has children, then the function calls itself, passing in a list of the element's children as the argument, and we start all over again. This is recursion.
 *
 * We also leverage this function to add Segment's `trackLink` listener to each element listed in the {@link LINK_IDS_TO_TRACK} list.
 *
 * @func
 * @param {NodeList} elements - A list of DOM Elements
 * @returns {void}
 *
 * @category Helper
 * @subcategory URL Tracking
 */
export const recursivelyUpdateLinks = (
  elements: NodeList,
  gclid?: string,
): void => {
  elements.forEach((element) => {
    if (element.nodeName.toLowerCase() === 'a') {
      if (
        (element as HTMLAnchorElement).getAttribute('data-disable-param-persist') === 'true'
      ) {
        return;
      }

      (element as HTMLAnchorElement).href = formatTrackedURL(
        (element as HTMLAnchorElement).href,
      );

      const { innerText, dataset } = element as HTMLAnchorElement;

      if (dataset.elementName && dataset.elementPosition) {
        analytics.trackLink(element as HTMLAnchorElement, 'Element Clicked', {
          gclid,
          elementPosition: dataset.elementPosition,
          elementName: dataset.elementName,
          elementText: innerText,
          elementType: dataset.elementType,
          url: window.location.href,
          path: window.location.pathname,
        });
      }
    }

    if (element.childNodes.length > 0) {
      recursivelyUpdateLinks(element.childNodes, gclid);
    }
  });
};
