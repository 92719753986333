/**
 * getAnonymousID
 * Gets the **ajs_anonymous_id** value, tries first from segment, then from cohesion, then from the cookies
 * @func
 * @returns {string | undefined}
 * @category Helper
 */
export const getAnonymousID = (): string | undefined => {
  const getFromSegment = () => (typeof analytics?.user === 'function'
    ? analytics.user()?.anonymousId()
    : undefined);

  const getFromCohesion = () => window?._Cohesion?.anonymousId;

  const getFromCookie = () => document.cookie
    .split(/; ?/)
    .find((cookie) => cookie.split('=')[0] === 'ajs_anonymous_id')
    ?.split('=')[1];

  return [getFromSegment, getFromCohesion, getFromCookie].map((f) => f()).find((o) => o);
};

/**
 * consolidateURLParams
 * Removes duplicate URL params
 * @func
 * @param {string[][]} params - An array of [key, value] pairs representing url params
 * @returns {Object}
 * @category Helper
 */
export const consolidateURLParams = (
  params: string[][],
): { [key: string]: string } => params.reduce((prev, [key, value]) => {
  if (!prev) {
    return {
      [key]: value,
    };
  }

  return {
    ...prev,
    [key]: value,
  };
}, {});

/**
 * parseURLParams
 * Extracts URL params from a string and returns them as an object
 * @func
 * @param {String} url - The full URL
 * @returns {Object}
 * @category Helper
 */
export const parseURLParams = (url: string): { [key: string]: string } => {
  /* eslint-disable @typescript-eslint/no-unused-vars */
  /* eslint-disable-next-line @typescript-eslint/naming-convention */
  const [_, params] = url.split('?');

  if (!params || !params.split('&')) {
    return {};
  }

  return consolidateURLParams(params.split('&').map((param) => param.split('=')));
};

/**
 * parseQueryStringParams
 * Extracts query string params from a string and returns them as elements in an array if they exist
 * @func
 * @param {String} querystring - querystring
 * @returns {Object}
 * @category Helper
 */
export const parseQueryStringParams = (querystring: string): string[] => {
  /* eslint-disable @typescript-eslint/no-unused-vars */
  /* eslint-disable-next-line @typescript-eslint/naming-convention */
  if (!querystring?.split('&')) {
    return [];
  }

  const urlParams = querystring.split('&');

  return urlParams;
};

/**
 * joinURLParams
 * Formats an object of URL params as a string
 * @func
 * @param {Object} params - An object of url params
 * @returns {string}
 * @category Helper
 */
export const joinURLParams = (params: { [key: string]: string }): string => {
  if (Object.keys(params).length < 1) return '';

  return Object.entries(params)
    .map((param) => param.join('='))
    .join('&');
};
