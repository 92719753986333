import { logCustomAttributes, logError } from '@red-digital/bricks';
import { useEffect } from 'react';

/**
 * useParamsTracking
 *
 * @func
 * @category Hooks
 */

export default function useParamsTracking(): void {
  useEffect(() => {
    try {
      const customAttributes = [];
      const url = new URL(window.location.href);
      const veh = url.searchParams.get('veh');
      const channelParam = url.searchParams.get('wmlspartner');

      if (veh) {
        customAttributes.push(
          {
            name: 'url.veh',
            value: veh,
          },
        );
      }
      if (channelParam) {
        customAttributes.push({
          name: 'url.wmlspartner',
          value: channelParam,
        });
      }
      if (customAttributes.length > 0) {
        logCustomAttributes(customAttributes);
      }
    } catch (urlError) {
      logError(urlError as Error || 'Unable to parse URL');
    }
  }, []);
}
